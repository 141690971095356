@font-face {
  font-family: 'Poppins';
  src: local('Poppins Regular'), local('Poppins-Regular'),
       url('/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins Medium'), local('Poppins-Medium'),
       url('/fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

* {
  font-family: 'Poppins', sans-serif !important;
  /* letter-spacing: 1px !important;
  text-transform: uppercase !important; */
}


.makecaps{
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
  font-size: 15px !important;
}


.makecapsDrawerMenu{
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
  /* font-size: 15px !important; */
}

.pageHeading{
  /* text-transform: uppercase !important; */
  letter-spacing: 2px !important;
  
  
}

.pageSubtitle{
  letter-spacing: 3px !important;
  font-size: 12px !important;
}

.divider {
  display: flex;
  
  
}

.divider::before{
  content: "";
    flex: 1;
}
.divider::after {
  content: "";
  flex: 1;
  }
  .glow::after {
    height: 6px;
    -webkit-filter: blur(1px);
    background: linear-gradient(to right, #ffd24d, #ffffff);
    border-radius: 5px;
   }
  
   .glow::before {
    height: 6px;
    -webkit-filter: blur(1px);
    background: linear-gradient(to right, #ffffff, #ffd24d);
    border-radius: 5px;
  }

   
   
  .line {
    align-items: center;
    margin: 1em -1em;
      
  }
  .line::after {
    height: 3px;
    margin: 0 1em;
   }
  .line::before {
    height: 3px;
    margin: 0 1em;
  }
  
  .one-line::after{
    background: #ffd24d;
  }
  
  .one-line::before{
    background: #ffd24d;
  }

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* background-image: url('/esay_bg.jpg'); */
}
.content-wrap {
  flex: 1;
  color:white;
}

.anchorTag{
  text-decoration:none;
  color: black;
}

.anchorTag:hover{
  text-decoration:underline;
  color: #003366;
}

 

.linkstyle:hover{
  color: black;
}

 /* === HEADING STYLE #2 === */
.article_title h1 {
  text-transform: capitalize;
}
/* .article_title h1:before {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 80px;  
  height: 5px;
  content: "";
  background-color: #ffd24d;
} */

.article_title h1 span {
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 4px;
  line-height: 3em;
  padding-left: 0.25em;
  text-align: right;
  color: #ffffff;
  padding-bottom: 10px;
}


.main-footer {
  color: black;
  background-color: #ffd24d;
  padding-top: 3em;
  position: relative;
  bottom: 0;
  width: 100%;
}

#carousel_image_id_active {
  filter: blur(0px) !important;
}

.engineering-info-list-dropdown .infolist ul {
  display: none;
}

.engineering-info-list-dropdown .infolist ul {
  padding-left: 5px;
}

.engineering-info-list-dropdown .infolist:hover {
  cursor: pointer;
}

.engineering-info-list-dropdown ul,
.engineering-info-list-dropdown li {
  list-style-type: none;
}

 .MuiList-root{
   background-color: #2e2b2b;
   color:#ffd24d;
   border:2px solid #ffd24d;
 }

  
 input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}




 